import elementResizeDetector from 'element-resize-detector';
// 引入ecahrts
import * as echarts from 'echarts';

const echartsConfig = {
  echarts,
  color: [
    '#3484FB',
    '#8EB4FF',
    '#C6DAFF',
    '#886EFF',
    '#008DEC',
    '#114C90',
    '#00BFA5',
  ],
  title: {
    top: 10,
    left: 0,
    textStyle: {
      fontSize: 14,
      color: 'rgba(0,141,236,0.9)',
    },
  },
  grid: {
    top: 40,
    left: 20,
    right: 20,
    bottom: 20,
    containLabel: true,
  },
  toolbox(element) {
    return {
      show: true,
      itemGap: 5,
      top: -5,
      feature: {
        dataView: {
          show: false,
        },
        dataZoom: {
          show: true,
          iconStyle: {
            opacity: 0,
          },
        },
        restore: { show: false },
        saveAsImage: { show: false },
        // 全屏具体实现
        myFull: {
          show: true,
          title: '全屏查看',
          icon: `path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891`,
          onclick: () => {
            // 注：yourEchartsId: 你的图表id
            // const element = document.getElementById(yourEchartsId);
            if (element.requestFullScreen) { // HTML W3C 提议
              element.requestFullScreen();
            } else if (element.msRequestFullscreen) { // IE11
              element.msRequestFullScreen();
            } else if (element.webkitRequestFullScreen) { // Webkit (works in Safari5.1 and Chrome 15)
              element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) { // Firefox (works in nightly)
              element.mozRequestFullScreen();
            }
            // 退出全屏
            if (element.requestFullScreen) {
              document.exitFullscreen();
            } else if (element.msRequestFullScreen) {
              document.msExitFullscreen();
            } else if (element.webkitRequestFullScreen) {
              document.webkitCancelFullScreen();
            } else if (element.mozRequestFullScreen) {
              document.mozCancelFullScreen();
            }
          },
        },
      },
    }
  },
  legend: {
    icon: 'circle',
    right: '1%',
    top: 10,
    itemWidth: 10,
    itemHeight: 10,
    borderRadius: 10,
    textStyle: {
      color: 'rgba(255,255,255,1)',
      fontSize: 12,
    },
  },
  axis: {
    nameTextStyle: {
      color: 'rgba(95, 95, 106, 1)',
    },
    splitLine: {
      lineStyle: {
        type: 'solid',
        color: 'rgba(255,255,255,0.06)',
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 0.5,
        type: 'dashed',
        color: 'rgba(151, 151, 151, 1)',
      },
    },
    axisLabel: {
      show: true,
      rotate: 0,
      margin: 10,
      fontSize: 13,
      color: 'rgba(169, 176, 194, 1)',
    },
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    confine: true,
    appendToBody: true,
  },
  linearGradientColor(color, orientation = 'top') {
    const type = orientation;
    const [ start, center, end ] = color || [ '#3484FB', '#62A1FF', '#FFFFFF' ];
    let colorStops = [
      {
        offset: 0,
        color: start,
      },
      {
        offset: 1,
        color: center,
      },
    ]
    if(end) {
      colorStops.splice(1, 0, {
        offset: 0.5,
        color: end,
      })
    }
    return {
      type: 'linear',
      x: type === 'right' ? 1 : 0, // 右
      y: type === 'bottom' ? 1 : 0, // 下
      x2: type === 'left' ? 1 : 0, // 左
      y2: type === 'top' ? 1 : 0, // 上
      colorStops,
    };
  },
  getDefaultSelected(echart) {
    let index = 0;
    echart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: 0,
    });
    echart.on('mouseover', (e) => {
      if (e.dataIndex !== index) {
        echart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: index,
        });
      }
    });
    echart.on('mouseout', (e) => {
      index = e.dataIndex;
      echart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: e.dataIndex,
      });
    });
  },
  onresize(elem) {
    elementResizeDetector().listenTo(elem, () => {
      echarts.init(elem).resize();
    });
  },
};

export default echartsConfig;
